<template>
  <v-container>
    <section>
      <div class="d-flex align-center mb-4">
        <h1 class="text-h5 font-italic">Relatórios Gerais</h1>
      </div>

      <FilterGroup class="mb-4" :filters="types" :queryKey="'type'" />

      <Clients v-if="type === 'clients'" />

      <Products v-if="type === 'products'" />
    </section>
  </v-container>
</template>

<script>
import Clients from "@/components/reports/Clients.vue";
import Products from "@/components/reports/Products.vue";
import FilterGroup from "@/components/filters/FilterGroup";

export default {
  data() {
    return {
      type: "clients",
      types: [
        { label: "ABC Clientes", value: "clients" },
        { label: "ABC Produtos", value: "products" },
      ],
    };
  },

  beforeMount() {
    if (this.$route.query.type) this.type = this.$route.query.type;
  },

  watch: {
    ["$route.query"](value) {
      this.type = value.type;
    },
  },

  components: {
    Clients,
    Products,
    FilterGroup,
  },
};
</script>
