<template>
  <loader-content v-if="loading" />

  <section v-else>
    <v-row class="mb-2" justify="end">
      <v-col cols="12" md="4">
        <v-card class="shadow rounded-lg">
          <v-list-item>
            <v-list-item-avatar class="opacity-primary" size="32">
              <v-icon color="primary">mdi-currency-usd</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>
                R$ {{ currencyMask(total) }}
              </v-list-item-title>

              <v-list-item-subtitle class="font-italic">
                Saldo total
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>

      <v-col cols="12" md="4">
        <v-card class="shadow rounded-lg">
          <v-list-item>
            <v-list-item-avatar class="opacity-primary" size="32">
              <v-icon color="primary">mdi-currency-usd</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>
                R$ {{ currencyMask(totalProfit) }}
              </v-list-item-title>

              <v-list-item-subtitle class="font-italic">
                Lucro total
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>

    <v-card class="shadow rounded-lg" color="backgorund">
      <v-data-table
        class="transparent"
        :headers="_headers"
        :items="reports"
        hide-default-footer
        disable-pagination
        disable-sort
      >
        <!-- user -->
        <template v-slot:[`item.name`]="{ item }">
          <router-link :to="`/user/${item.userID || item.id}`">
            <v-chip color="primary" link>
              <v-icon left small>mdi-account</v-icon>

              <span>{{ item.name }}</span>
            </v-chip>
          </router-link>
        </template>

        <!-- saldo -->
        <template v-slot:[`item.saldo`]="{ item }">
          R$ {{ currencyMask(item.saldo) }}
        </template>

        <!-- totalLucro -->
        <template v-slot:[`item.totalLucro`]="{ item }">
          R$ {{ currencyMask(item.totalLucro) }}
        </template>

        <!-- percent -->
        <template v-slot:[`item.percent`]="{ item }">
          {{ item.percent }}%
        </template>
      </v-data-table>
    </v-card>
  </section>
</template>

<script>
import { request } from "@/services";
import { currencyMask } from "@/utils";

export default {
  data() {
    return {
      loading: true,
      reports: [],
    };
  },

  beforeMount() {
    this.getData();
  },

  computed: {
    _headers() {
      return [
        {
          text: "Cliente",
          value: "name",
        },
        {
          text: "E-mail",
          value: "email",
        },
        {
          text: "Saldo",
          value: "saldo",
        },
        {
          text: "Lucro",
          value: "totalLucro",
        },
        {
          text: "Percentual",
          value: "percent",
        },
      ];
    },

    total() {
      let total = 0;

      this.reports.map((e) => {
        total += Number(e.saldo);
      });

      return total;
    },

    totalProfit() {
      let total = 0;

      this.reports.map((e) => {
        total += Number(e.totalLucro);
      });

      return total;
    },
  },

  methods: {
    async getData() {
      try {
        this.loading = true;

        const payload = {
          method: "listUsersRelatorio",
        };

        await request(payload).then((res) => {
          this.reports = res.users;
        });
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    openUser(id) {
      this.$router.push({ path: `/customer/${id}?callback=reports` });
    },

    currencyMask,
  },
};
</script>
