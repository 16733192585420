<template>
  <loader-content v-if="loading" />

  <section v-else>
    <v-card class="shadow rounded-lg mb-6" color="backgorund">
      <v-data-table
        class="transparent"
        :headers="_headers"
        :items="reports"
        hide-default-footer
        disable-pagination
        disable-sort
      >
        <!-- name -->
        <template v-slot:[`item.nomeContrato`]="{ item }">
          <router-link :to="`/products/${item.id}`">
            <span>{{ item.nomeContrato }}</span>
          </router-link>
        </template>

        <!-- totalInvestido -->
        <template v-slot:[`item.totalInvestido`]="{ item }">
          R$ {{ currencyMask(item.totalInvestido) }}
        </template>

        <!-- percent -->
        <template v-slot:[`item.percent`]="{ item }">
          {{ item.percent }}%
        </template>
      </v-data-table>
    </v-card>
  </section>
</template>

<script>
import { request } from "@/services";
import { currencyMask } from "@/utils";

export default {
  data() {
    return {
      loading: true,
      reports: [],
    };
  },

  beforeMount() {
    this.getData();
  },

  computed: {
    _headers() {
      return [
        {
          text: "Produto",
          value: "nomeContrato",
        },
        {
          text: "Total investido",
          value: "totalInvestido",
        },
        {
          text: "Percentual",
          value: "percent",
        },
      ];
    },
  },

  methods: {
    async getData() {
      try {
        this.loading = true;

        const payload = {
          method: "listContratosRelatorio",
        };

        await request(payload).then((res) => {
          this.reports = res.contratosRelatorio;
        });
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    openContract(id) {
      this.$router.push({ path: `/contracts/${id}?callback=reports` });
    },

    currencyMask,
  },
};
</script>

<style></style>
